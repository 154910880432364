<template>
    <!--components/CountTable.vue-->
    <table class="count-table">
        <tbody>
            <tr>
                <td>
                    <div class="clickable" @click="download_task_from_community" v-if="(task_ref.valueOf().hasOwnProperty('from_community')) && (task_ref.valueOf()['from_community'] === true)">
                        <span class="class_community_indicator">community</span><br/>
                        <i class="fa-light fa-download"></i>
                    </div>
                     <div v-else>
                        <span class="class_no_link class_community_indicator">community</span><br/>
                        <i class="class_no_link fa-light fa-download"></i>                        
                    </div>
                </td>

                <td>
                    <i :class="{'fa-duotone fa-lg fa-thumbs-up': true, 'clickable': true, 'voted': voted.valueOf()}" @click.prevent.stop="upvote_handler"></i><br/>
                    {{ task_ref.valueOf().num_voters || 0 }}
                </td>
                <td> 
                    <div v-if="(task_ref.valueOf().parent_tasks) && (task_ref.valueOf().parent_tasks.length > 0)">
                        <!-- Community linkto URL -->
                        <NuxtLink 
                            v-if="((task_ref.valueOf().hasOwnProperty('from_community')) && (task_ref.valueOf()['from_community'] === true))" 
                            :to="community_url + '/?linkto=' + `${task_ref.valueOf().id}`" 
                            class="text-nowrap" 
                            no-prefetch 
                            target="_blank"
                            :disabled="(is_client_side) &&(! page_mounted_state)"
                            :class="{'disabled':  (is_client_side) &&(! page_mounted_state)}" 
                        ><i class="fa-duotone fa-lg fa-link-simple"></i></NuxtLink>

                        <!-- Local instance linkto URL -->
                        <NuxtLink 
                            v-if="(! ((task_ref.valueOf().hasOwnProperty('from_community')) && (task_ref.valueOf()['from_community'] === true)))"  
                            :to="'/?linkto=' + `${task_ref.valueOf().id}`" 
                            class="text-nowrap" 
                            no-prefetch
                            :disabled="(is_client_side) &&(! page_mounted_state)"
                            :class="{'disabled':  (is_client_side) &&(! page_mounted_state)}" 
                        ><i class="fa-duotone fa-lg fa-link-simple"></i></NuxtLink>
                        <br/>
                        {{ task_ref.valueOf().parent_tasks.length}}
                    </div>
                     <div v-else>
                        <i class=" class_no_link fa-duotone fa-lg fa-link-simple"></i>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script setup>
    import { downloadTask } from '~/services/taskService'
    const props = defineProps({
        item: {
            type: Object
        }
    });
    const is_client_side = process.client;
    const page_mounted_state = usePageMountedState();

    let task_ref = ref(props.item);
    let uid = 'anonymous'
    let voted = ref(false);

    if ((process.client) && (localStorage.getItem('isAuthenticated') == '1')) {
        uid = localStorage.getItem('uid');
    }

    if (process.client) {
        if ((task_ref.value.hasOwnProperty('voters')) && (task_ref.value.voters.hasOwnProperty(uid))) {
            voted.value = true;
        }
    }

const upvote_handler = async () => {
    const authState = useAuthState();
    if (!authState.value) {
        dkAlert("Please sign-in in order to vote.");
        return;
    }
    await showWaitPage();
    let res = await upvote(task_ref.value.id);
    let cloned = JSON.parse(JSON.stringify(task_ref.value));
    if (voted.value === false) {
        cloned.num_voters = cloned.num_voters + 1;
        voted.value = true;
        if (! cloned.hasOwnProperty('voters')) {
            cloned['voters'] = {};
        };
        cloned['voters'][uid] = {};
    } else {
        cloned.num_voters = cloned.num_voters - 1;
        voted.value = false;
        if (! cloned.hasOwnProperty('voters')) {
            cloned['voters'] = {};
        };
        delete cloned['voters'][uid];
    }
    task_ref.value = cloned;
    hideWaitPage();
}

const download_task_from_community = async () => {
    await showWaitPage();
    let new_task = await downloadTask(task_ref.value.id);
    hideWaitPage();

    dkAlert("Task downloaded.  You will now be redirected to the page for the downloaded task.", function(){
        dkNavigateTo(`/tasks/${new_task.id}`);
    });
    
    /*
    alert("Task downloaded.  You will now be redirected to the page for the downloaded task.");
    dkNavigateTo(`/tasks/${new_task.id}`);
    */
}

let community_url = getCommunityURL();


onMounted(() => {
    //console.log("🚀 ~ file: CountTable.vue:97 ~ onMounted ~ task_ref:", task_ref.value)
})

</script>

<style scoped>
    .count-table td {
        width: 40px;
        text-align: center;
        padding-right: 15px;
        padding-left: 15px;
    }
    .class_community_indicator {
        font-size: 10px;
        border: 1px solid orange;
        border-radius: 5px;
        padding-left: 3px;
        padding-right: 3px;
        padding-top: 2px;
        background: #fffad7;
    }
    .class_no_link{
        visibility: hidden;
    }
</style>
