<template>
    <!--components/Task.vue-->
    <div :class="{'pb-1 pt-1': true, 'border-bottom': show_bottom_border}" v-if="! is_javascript">
        <table class="line-up-with-logo">
            <tbody>
                <tr>
                    <td width="40" valign="top" v-if="isAdmin()">
                        <input type="checkbox" :value="task.id" class="task_checkboxes" @change.stop.prevent="toggle_top_checkbox"/>
                    </td>
                    <td class="left_side_column">
                        <div style="position: relative; padding-left: 20px">
                            <h2 class="fs-6">
                                <NuxtLink 
                                    v-if="((item.hasOwnProperty('from_community')) && (item['from_community'] === true))" 
                                    :to="`${community_url}/tasks/${ item.id }`" 
                                    no-prefetch
                                >
                                    {{ item.title }} 
                                </NuxtLink>
                                
                                <NuxtLink v-else 
                                    :to="`/tasks/${ item.id }${append_copliot}`" 
                                    no-prefetch
                                >
                                    {{ item.title }}
                                </NuxtLink>

                            </h2>
                            <div v-if="typeof(selected_logo_html) != 'undefined'" v-html="selected_logo_html" class="vendor_logo_container">
                            </div>

                            <RenderDescription :description="item.description" :truncate="true" :max_chars="399" :mode="'strict'" :show_full_description_by_default="false"/>

                            <TagList :tags="item.tags"/>

                        </div>
                    </td>
                    <td class="right_side_column nowrap">
                        <CountTable :item="item" v-if="! show_pending_approve_reject_box"/>
                        <PendingApproveRejectComponent v-if="show_pending_approve_reject_box" :task="item"/>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup>
    const props = defineProps({
        item: {
            type: Object
        },
        show_bottom_border: {
            type: Boolean,
            default: true
        }
    });
    const item = props.item;
    const task = props.item;
    if (! item.tags) {
        item.tags = [];
    }

    const is_client_side = process.client;

    const page_mounted_state = usePageMountedState();
    let community_url = getCommunityURL() || '';
    community_url = community_url.replace(/\/+$/,'');
    
    let selected_logo_html = getTaskLogo(item);

    let is_javascript = isJavascript(props.item);
    let space_dropdown_state = useSpaceDropdownState();

    let append_copliot = "";
    if (item.title.toLowerCase().includes('agent')) {
        append_copliot = "?agent=1";
    } else if (item.description.includes('AI agent task')) {
        append_copliot = "?agent=1";
    }

    let show_pending_approve_reject_box = false;
    const route = useRoute();
    if ((route.query.hasOwnProperty('pending')) && (route.query.pending == 'true')) {
        show_pending_approve_reject_box = true;
    }

    const toggle_top_checkbox = (event) => {
        let tasks = [];
        document.querySelectorAll('.task_checkboxes').forEach((elem) => {
            if (elem.checked) {
            tasks.push(elem.value)
            }
        })
        if (tasks.length > 0) {
            document.getElementById('select_all_task').checked = true;
        } else {
            document.getElementById('select_all_task').checked = false;
        }
    }
</script>

<style scoped>
    .right_side_column {
        width: 140px;
        vertical-align: top;
        padding-right: 15px;
    }

    table.line-up-with-logo {
        width: calc(100% - 20px);
    }

    a {
        text-decoration: none;
    }

    .vendor_logo_container {
        position: absolute;
        z-index: 1;
        top: 0px;
        left: -14px;
        height: 25px;
        width: 25px;
    }
    .nowrap {
        white-space: nowrap;
    }
    .left_side_column {
        /*
        If the description contains a code block, or a string of characters that cause this task to be 
        super wide and break the layout.  Setting max-width here seems to be the best option.
        */
        max-width: 600px;
    }
</style>
